<template>
  <v-content>
    <Status/>
  </v-content>
</template>

<script>
  import Status from '../components/Status'

  export default {
    name: 'status',
    components: {
      Status
    }
  }
</script>
